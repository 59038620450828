import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { Select } from 'Components/form';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

type SingleUserType = {
  isOpen: boolean;
  onClose: () => void;
  cities: Array<any>;
  paymentTypesUuid: string;
  getPayments: (params) => any;
  cityUser: any;
};

function CityModal({ isOpen, onClose, cities, paymentTypesUuid, getPayments, cityUser }: SingleUserType): JSX.Element {
  const validationSchema = Yup.object().shape({
    cityUuid: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });
  const userCity = cities.length > 0 && cityUser && cities.find((item) => item.label === cityUser);

  const initialValues = {
    cityUuid: userCity?.value || '',
  };

  const goToPayment = async ({ cityUuid }) => {
    await getPayments({ cityUuid, paymentTypesUuid });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={` city-modal modal-mobile--full`}>
      <h2 className="edit-user-modal__header">Wybierz miasto</h2>
      <p className="city-modal--descript">
        To ostatnia informacja, której potrzebujemy. Pozwoli nam to coś tam coś tam — wyjaśnienie po co zbieramy takie info.
      </p>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={async (values) => goToPayment(values)}>
        {({ errors, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className="edit-user-modal__form myProfilePacket__city-form">
              <Field
                required
                label="Miasto"
                isSearchable
                name="cityUuid"
                placeholder="Wybierz miasto"
                component={Select}
                options={cities}
                errors={errors}
              />
              <Button label="Przejdź do płatności" onClick={handleSubmit} />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default CityModal;
