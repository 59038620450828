import React, { useState, useEffect } from 'react';
import { SVG, Button } from 'Components';
import { SVG_TYPE, ROUTES, USER_TYPE } from 'Shared/enums';
import { useNavigate } from 'react-router';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { MainType } from './Container';
import { CityModal } from './components';
import moment from 'moment';

const MyProfilePacket = (props: MainType) => {
  const { actions, paymentsLabel, cities, authorizedUser } = props;
  const { getPaymentsLabel, getPayments, getCities } = actions;
  const [isOpenCityModal, setIsOpenCityModal] = useState(false);
  const [activePacket, setActivePacket] = useState(undefined);
  const isUserLogged = !!authorizedUser && authorizedUser !== 'init';
  const userCity = isUserLogged && authorizedUser?.city;
  const isFromFailedPayments = sessionStorage.getItem('isFromFailedPayments') === 'true';
  const isUserPremium = authorizedUser && authorizedUser !== 'init' && authorizedUser.type === USER_TYPE.PREMIUM;
  const expiredDate = isUserPremium && authorizedUser.deadline.deadline;

  const isMobile = window.innerWidth < 1024;
  const navigate = useNavigate();

  useEffect(() => {
    getPaymentsLabel();
  }, []);

  useEffect(() => {
    getCities();
  }, []);

  const Packet = ({ data: { days, oldPrice, price, value }, extend, index }: any) => {
    const isNumber = typeof extend === 'number';
    const isActive = activePacket === value;
    const isFirst = index === 0;

    const newDate = expiredDate ? moment(expiredDate).add(extend, 'days').format('DD.MM.YYYY') : null;
    return (
      <div className={`myProfilePacket__item ${isActive ? 'myProfilePacket__item--active' : ''}`}>
        <div className="time">
          {days === 'unlimited' ? <SVG type={SVG_TYPE.STAR} /> : ''}
          {days === 'unlimited' ? 'Bez limitu' : `${days} dni`}
        </div>
        {isNumber && (
          <div className="expired-date">
            Ważny do: <span>{newDate}</span>
          </div>
        )}
        {oldPrice != 0 && (
          <div className="oldPrice">
            <span>{oldPrice} zł</span>
          </div>
        )}
        <div className={`price ${isFirst && isUserPremium ? 'price--margin-bottom' : ''}`}>{price} zł</div>
        {isActive ? (
          <div className="active">
            <SVG type={SVG_TYPE.CIRCLE_CHECKED} />
          </div>
        ) : (
          <Button
            label="Wybierz"
            className="btn--small"
            stroke
            onClick={() => {
              setActivePacket(value);
            }}
          />
        )}
      </div>
    );
  };

  const getBack = () => {
    if (isFromFailedPayments) {
      sessionStorage.removeItem('isFromFailedPayments');
      navigate({ pathname: ROUTES.MYPROFILE });
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <CityModal
        getPayments={getPayments}
        paymentTypesUuid={activePacket}
        isOpen={isOpenCityModal}
        onClose={() => setIsOpenCityModal(false)}
        cities={cities}
        cityUser={userCity}
      />
      <div className="myProfilePacket">
        <div className="myProfilePacket__top">
          <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
          <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--first" />
          <SVG type={SVG_TYPE.ADMIN_ANIMATED_ELEMENT} className="dashboard__header-animated-element dashboard__header-animated-element--second" />
          {isMobile && (
            <span
              className="myProfilePacket__top--back"
              onClick={() => {
                getBack();
              }}>
              <SVG type={SVG_TYPE.CHEVRONE} />
            </span>
          )}
          {!isMobile && (
            <span
              className="myProfilePacket__top--logo--back"
              onClick={() => {
                getBack();
              }}>
              <SVG type={SVG_TYPE.CHEVRONE} />
            </span>
          )}
          <div className="myProfilePacket__top--logo">
            <SVG type={SVG_TYPE.LOGO_GROUP} />
          </div>
        </div>
        <div className="myProfilePacket__content">
          <div className="header">{isUserPremium ? 'Przedłuż swój pakiet Premium i ucz się bez przerw!' : 'Kup pakiet Premium'}</div>
          {isUserPremium ? (
            <ul className="list-premium">
              <li className="list-premium__item">
                Twój pakiet premium jest nadal aktywny, ale możesz już teraz zapewnić sobie dalszy dostęp do wszystkich funkcji bez przerwy w nauce.
                Wybierz opcję, która najlepiej pasuje do Twoich potrzeb.
              </li>
              <li className="list-premium__item list-premium__item--bold">
                Nie trać czasu – przedłuż teraz i zachowaj dostęp do wszystkich pytań i testów bez przerw!
              </li>
            </ul>
          ) : (
            <ul className="list">
              <li className="list__item">
                <SVG type={SVG_TYPE.CHECKED} />
                Nielimitowane egzaminy wraz z wyjaśnieniem błędów
              </li>
              <li className="list__item">
                <SVG type={SVG_TYPE.CHECKED} />
                Szczegółowe statystyki nauki i egzaminów + procent szans na zdanie egzaminu państwowego
              </li>
              <li className="list__item">
                <SVG type={SVG_TYPE.CHECKED} />
                Nielimitowana ilość zadań w module nauki
              </li>
              <li className="list__item">
                <SVG type={SVG_TYPE.CHECKED} />
                Informacje o tym, z jakimi działami słabiej sobie radzisz{' '}
              </li>
            </ul>
          )}
          <div className="myProfilePacket__items">
            {paymentsLabel && paymentsLabel.length > 0 && (
              <>
                <div className="myProfilePacket__items-desktop">
                  {paymentsLabel.map((item, index) => {
                    return <Packet key={item.value} data={item} index={index} extend={isUserPremium ? item.days : null} />;
                  })}
                </div>
              </>
            )}
          </div>
          <div className={`myProfilePacket__buttons ${activePacket ? 'myProfilePacket__buttons--active' : ''}`}>
            <Button label="Kup pakiet" className="btn--small buy" onClick={() => setIsOpenCityModal(true)} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfilePacket;
