import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { Textarea, Switch, Input, DropFileInput } from 'Components/form';
import { Field, Formik } from 'formik';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import * as Yup from 'yup';

type EditQuestionModal = {
  isOpen: boolean;
  onClose: () => void;
  question: any;
  editQuestion: any;
};

function EditUserModal({ isOpen, onClose, question, editQuestion }: EditQuestionModal): JSX.Element {
  const initialValues = {
    explanation: question?.explanation || '',
    isDifficult: question?.isDifficult,
    youtubeLink: question?.youtubeLink || '',
    file: question?.file || '',
  };
  const validationSchema = Yup.object().shape({
    explanation: Yup.string().nullable().required(VALIDATION_MESSAGES.REQUIRED),
  });
  const handleOnSubmit = async (values, actions) => {
    await editQuestion(question.uuid, values);
    actions.resetForm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={`edit-question-modal modal-mobile--full`}>
      <h2 className="edit-question-modal__header">Edycja pytania</h2>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => handleOnSubmit(values, actions)}>
        {({ errors, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="edit-question-modal__form">
            <Field
              className={''}
              label="Wyjaśnienie"
              name="explanation"
              placeholder="Wpisz wyjaśnienie"
              required
              component={Textarea}
              errors={errors}
              treshold={0}
            />
            <Field
              className={''}
              label="Dodatkowe media"
              name="file"
              placeholder="Przeciągnij i upuść lub"
              required
              component={DropFileInput}
              errors={errors}
              onFileDrop={() => null}
              removeAvatar={() => null}
              accept=".jpg,.jpeg,.png,.bmp,.gif,.webp"
            />
            <Field
              className={''}
              label="Link do YouTube"
              name="youtubeLink"
              placeholder="Wprowadź link"
              required
              component={Input}
              errors={errors}
              treshold={0}
            />
            <Field setFieldValue={setFieldValue} label="Oznacz jako pytanie trudne" name="isDifficult" component={Switch} />
            <div className="edit-question-modal__buttons">
              <Button label="Anuluj" className="add-user-modal__add-button" type="button" onClick={() => onClose()} stroke />
              <Button label="Zapisz" className="add-user-modal__add-button" type="submit" onClick={() => null} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
}

export default EditUserModal;
