import SVG from 'Components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { Button } from 'Components';

type BarType = {
  title: string;
  subTitle?: string;
  onClick?: () => void;
  extend?: () => void;
};

function Bar({ title, subTitle, onClick, extend }: BarType) {
  return (
    <div className={`bar ${onClick ? 'bar--onClick' : ''} ${extend ? 'bar--button' : ''}`} onClick={onClick}>
      <div>
        <h4 className="bar__title">{title}</h4>
        {subTitle ? <p className="bar__sub-title">{subTitle}</p> : null}
      </div>
      {onClick && <SVG type={SVG_TYPE.CHEVRONE} className="svg--chevron" />}
      {extend && <Button className="bar__button" label="Przedłuż" onClick={() => extend()} />}
    </div>
  );
}

export default Bar;
