/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSearchParams } from 'react-router-dom';

type DashboardCategoryProps = {
  type: string;
  selectedType: string;
  label: string;
  style?: 'small' | 'big';
};

function DashboardCategory({ type, selectedType, label, style }: DashboardCategoryProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClickType = (type: string) => {
    setSearchParams({ type, page: '1' });
  };
  return (
    <p
      className={`dashboard__category admin-learning-page__dashboard__category--${style}   ${
        selectedType === type ? 'dashboard__category--active' : ''
      }`}
      onClick={() => handleClickType(type)}>
      {label}
    </p>
  );
}
export default DashboardCategory;
