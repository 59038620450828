import React from 'react';
import ReactDOM from 'react-dom/client';
import 'Assets/Sass/style.scss';
import { Provider } from 'react-redux';
import { store } from 'Store/Store';
import { BrowserRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

import App from 'App';

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

if (!isLocalhost && 'serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/dashboard/service-worker.js')
      .then((reg) => {
        console.log('zarejestrowano Service Workera', reg);
        reg.onupdatefound = () => {
          const newSW = reg.installing;
          if (newSW) {
            newSW.onstatechange = () => {
              if (newSW.state === 'installed' && navigator.serviceWorker.controller) {
                console.log('🚀 Nowa wersja dostępna, odśwież stronę!');
              }
            };
          } else {
            console.log('1');
          }
          console.log('2');
        };
      })
      .catch((err) => console.error('❌ Błąd Service Workera:', err));
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
