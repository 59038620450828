import { connect } from 'react-redux';
import DrivingTestSummaryPage from './Main';
import { drivingTestSummary, } from 'Store/Selectors/DrivingTestSelector';
import { bindActionCreators } from 'redux';
import { getAuthorizedUser } from 'Store/Actions/Auth';
import { getSingleSummary } from 'Store/Actions/DrivingTest';
import { authorizedUser } from 'Store/Selectors/AuthSelector';
import { getDrivingTest } from 'Store/Actions/DrivingTest';
 
type ConnectedP = {
  drivingTestSummary: any
  authorizedUser: any;
};

type DispatchedP = {
  actions: {
    getDrivingTest: () => any;
    getSingleSummary: any;
    getAuthorizedUser: () => (dispatch: any) => Promise<any>;
  };
};

const mapStateToProps = (state) => ({
  drivingTestSummary: drivingTestSummary(state),
  authorizedUser: authorizedUser(state),
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators (
    {
      getDrivingTest: getDrivingTest,
      getSingleSummary: getSingleSummary,
      getAuthorizedUser: getAuthorizedUser,
    },
    dispatch
  )
});

export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(DrivingTestSummaryPage);

