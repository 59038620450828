import { useState, useEffect } from 'react';
import Button from 'Components/Button';
import Modal from 'Components/Modal';
import { DropFileInput } from 'Components/form';
import { Field, Formik } from 'formik';

type changePasswordModalType = {
  isOpen: boolean;
  onClose: () => void;
  user: any;
  editAvatar: (uuid, file, boolean) => void;
};

function ChangeUserNameModal({ isOpen, onClose, user, editAvatar }: changePasswordModalType): JSX.Element {
  const [isNewFile, setIsNewFile] = useState(false);
  const [fileError, setFileError] = useState('');
  const [initialValues, setInitialValues] = useState({
    avatar: user?.avatar.length > 0 ? user?.avatar : null,
  });

  useEffect(() => {
    if (isOpen) {
      setFileError('');
      setIsNewFile(false);
      setInitialValues({
        avatar: user?.avatar.length > 0 ? user?.avatar : null,
      });
    }
  }, [isOpen, user]);

  const handleOnClose = () => {
    onClose();
    setFileError('');
  };

  const handleOnSubmit = async (values, resetForm) => {
    isNewFile && (await editAvatar(user.uuid, values.avatar[0], true));
    resetForm();
    handleOnClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={() => handleOnClose()} className={`edit-user-modal`}>
      <h2 className="edit-user-modal__header">Zmiana avatara</h2>
      <Formik
        key={isOpen ? 'open' : 'closed'}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => handleOnSubmit(values, resetForm)}>
        {({ errors, handleSubmit, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit} className="edit-user-modal__form">
              <Field
                className={''}
                label="Avatar"
                name="avatar"
                placeholder="Przeciągnij i upuść lub"
                required
                component={DropFileInput}
                errors={errors}
                onFileDrop={(files) => {
                  const file = files[0];
                  if (file && file.size > 5 * 1024 * 1024) {
                    setFileError('Rozmiar pliku nie może przekraczać 5MB.');
                    setIsNewFile(false);
                    setTimeout(() => {
                      setFieldValue('avatar', null);
                    }, 1);
                  } else {
                    setFileError('');
                    setIsNewFile(true);
                  }
                }}
                removeAvatar={() => {
                  setIsNewFile(false);
                }}
                accept=".jpg,.jpeg,.png,.bmp,.gif,.webp"
              />
              {fileError && <div className="edit-avatar--error">{fileError}</div>}
              <div className="edit-user-modal__buttons">
                <Button label="Anuluj" className="add-user-modal__add-button" type="button" onClick={() => handleOnClose()} stroke />
                <Button label="Zapisz" className="add-user-modal__add-button" type="submit" onClick={() => null} />
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ChangeUserNameModal;
