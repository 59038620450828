import SVG from 'Components/SVG';
import Loader from 'Components/Loader';

interface ButtonProps {
  label: string;
  onClick: () => void;

  type?: 'button' | 'submit' | 'reset';
  className?: string;
  svg_type?: string;
  iconClass?: string;
  disabled?: boolean;
  iconOnLeft?: boolean;
  stroke?: boolean;
  isLoading?: boolean;
}

function Button({ label, onClick, className, svg_type, iconClass, disabled, iconOnLeft, stroke, type = 'button', isLoading = false }: ButtonProps) {
  const handleOnClick = () => {
    if (!isLoading) {
      onClick();
    }
  };
  const hasStroke = className && className.includes('btn--stroke--orange');

  return (
    <button
      type={type}
      className={`btn ${className ? className : ''} ${disabled ? 'btn--disabled' : ''} ${stroke ? 'btn--stroke' : ''} ${
        isLoading ? 'btn--loading' : ''
      }`}
      onClick={() => handleOnClick()}>
      {iconOnLeft && svg_type ? <SVG type={svg_type} className={iconClass} /> : null}
      {label}
      {!iconOnLeft && svg_type ? <SVG type={svg_type} className={iconClass} /> : null}
      {isLoading && <Loader isWhite={hasStroke ? false : true} />}
    </button>
  );
}

export default Button;
