import React, { useRef } from 'react';
import { useLocation } from 'react-router';
import { ROUTES, SVG_TYPE, USER_ROLES } from 'Shared/enums';
import { useEffect, useState } from 'react';
import { NavLink, generatePath } from 'react-router-dom';
import SVG from 'Components/SVG';
import { MainType } from './Container';

function Navigation(props: MainType) {
  const {
    actions: { getAuthorizedUser },
    authorizedUser,
  } = props;
  const isDashboard = useLocation().pathname.split('/')[1] === 'dashboard';
  const isMobile = window.innerWidth < 1024;
  const { pathname } = window.location;
  const isPacketPage = pathname === ROUTES.MYPROFILE_PACKET && isMobile;
  const isExamPage = pathname === ROUTES.EXAM && isMobile;
  const isLessonPage = pathname.includes('lesson') && isMobile;
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const slide: any = document.querySelector('.slide');
    if (isDashboard && !isPacketPage) {
      setIsNavVisible(true);
      if (isMobile) {
        slide.style.height = 'calc(100% - 64px)';
      } else {
        slide.style.height = '100vh';
        slide.style.overflow = 'auto';
      }
    } else {
      slide.style.overflow = 'hidden';
      setIsNavVisible(false);
      if (isExamPage) {
        slide.style.height = 'calc(100% - 64px)';
      } else {
        slide.style.height = '100%';
      }
    }
  }, [location.pathname, window.innerWidth, isDashboard]);

  useEffect(() => {
    const slide: any = document.querySelector('.slide');
    if (isLessonPage) {
      setIsNavVisible(false);
      slide.style.height = '100%';
    }
  }, [location.pathname, window.innerWidth, isDashboard]);

  useEffect(() => {
    if (!authorizedUser) {
      getAuthorizedUser();
    } else {
      setUserRole(authorizedUser.role);
    }
  }, [authorizedUser]);
  const navigationRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navigationRef.current && !navigationRef.current.contains(event.target)) {
        setIsNavOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const linkToQuestionDatabase = generatePath(ROUTES.QUESTIONS_DATABASE, { category: 'all', page: '1' });
  const linkToUsers = generatePath(ROUTES.USERS, { role: 'client', page: '1' });
  const linkToLearning = generatePath(ROUTES.LEARNING, { submenu: 'departments' });
  const isQuestionDatabaseActive = location.pathname.startsWith('/dashboard/question-database');
  const isUsersActive = location.pathname.startsWith('/dashboard/users');
  const isLearningPageActive = location.pathname.startsWith('/dashboard/learning');

  return (
    <>
      {isNavVisible ? (
        <>
          <nav ref={navigationRef} className={`navigation ${isNavOpen ? 'navigation--open' : ''}`}>
            {!isMobile && (
              <div className="navigation-item navigation__logo" onClick={() => setIsNavOpen(!isNavOpen)}>
                <SVG type={SVG_TYPE.LOGO_ROYAL} />
              </div>
            )}
            {userRole === USER_ROLES.CLIENT && (
              <>
                <NavLink to={linkToLearning} className={`${isLearningPageActive ? 'navigation-item active' : 'navigation-item'}`}>
                  <SVG type={SVG_TYPE.STUDY} />
                  {!isMobile && <div className="navigation-item__name">Nauka</div>}
                </NavLink>
                <NavLink to={ROUTES.EXAM} className="navigation-item">
                  <SVG type={SVG_TYPE.EXAM} />
                  {!isMobile && <div className="navigation-item__name">Egzamin</div>}
                </NavLink>
                <NavLink to={ROUTES.GAMIFICATION} className="navigation-item">
                  <SVG type={SVG_TYPE.TROPHY} />
                  {!isMobile && <div className="navigation-item__name navigation-item__name--darkest">Grywalizacja(wkrótce)</div>}
                </NavLink>
                <NavLink to={ROUTES.MYPROFILE} className="navigation-item">
                  <SVG type={SVG_TYPE.PERSON} />
                  {!isMobile && <div className="navigation-item__name">Profil</div>}
                </NavLink>
              </>
            )}
            {userRole === USER_ROLES.ADMIN && (
              <>
                <NavLink to={ROUTES.STATISTICS} className="navigation-item">
                  <SVG type={SVG_TYPE.STATS} />
                  {!isMobile && <div className="navigation-item__name">Statystyki</div>}
                </NavLink>
                <NavLink to={linkToUsers} className={` ${isUsersActive ? 'navigation-item active' : 'navigation-item'}`}>
                  <SVG type={SVG_TYPE.USERS} />
                  {!isMobile && <div className="navigation-item__name">Lista użytkowników</div>}
                </NavLink>
                <NavLink to={linkToQuestionDatabase} className={`${isQuestionDatabaseActive ? 'navigation-item active' : 'navigation-item'}`}>
                  <SVG type={SVG_TYPE.FOLDER_QUESTION} />
                  {!isMobile && <div className="navigation-item__name">Baza pytań</div>}
                </NavLink>
                <NavLink to={`${ROUTES.ADMIN_LEARNING}?page=1&type=Wszystkie`} className="navigation-item">
                  <SVG type={SVG_TYPE.STUDY} />
                  {!isMobile && <div className="navigation-item__name">Nauka</div>}
                </NavLink>
                <NavLink to={ROUTES.MYPROFILE} className="navigation-item">
                  <SVG type={SVG_TYPE.PERSON} />
                  {!isMobile && <div className="navigation-item__name">Profil</div>}
                </NavLink>
              </>
            )}
          </nav>
        </>
      ) : null}
    </>
  );
}

export default Navigation;
